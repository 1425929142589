@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300&family=Overpass+Mono&display=swap);
body{
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: normal;
}


.bg{
  background: #100828;
  color: #FFFFFF;
}


.bg-alt{
  background: #E5E5E5;
}


.my-name{
  height: 31px;
  font-family: 'Overpass Mono', monospace;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #FFFFFF;
}


.navbar-container{
  padding-top: 10px;
}


.menu{
  height: 19px;
  text-align: right;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: normal;
}


.menu-item{
  padding-right: 25px;
  cursor: pointer;
}


.summary{
  font-size: 20px;
  font-weight: lighter;
  line-height: 30px;
  color: #FFFFFF;
}


.skill{
  font-size: 14px !important;
  color: #685F81 !important;
  border-color: #685F81 !important;
  margin: 5px !important;
}


.app-btn{
  background-color: #FFFF00 !important;
}


.app-btn-alt{
  background-color: #100828 !important;
  color: #FFFFFF !important;
}


.social-icon{
  margin-right: 25px;
  font-size: 22px;
  color: #FFFFFF;
}

.section-title{
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 27px;
}

.card-custom{
  height: 250px;
  border-radius: 4px;
  font-weight: 300;
  font-size: 14px;
  color: #023734;
  background: #F4F1FC;
}

.card-footer {
  color: #023734;
  background: #F4F1FC;
}

.card-custom-title{
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  /* color: #FFFFFF; */
}

.exp-card{
  background: #F4F1FC !important;
}

.job-title{
  color: #100828;
  font-weight: bold;
  font-size: 22px;
}

.company-info{
  color: #685F81 !important;
  font-weight: normal;
  font-size: 14px;
}

.duration{
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: #685F81 !important;
}

.company-logo{
  width: 99.79px;
  left: 140px;
}


.duties{
  list-style-type: circle;
  font-size: 14px;
  padding-left: 1.2em;
}

.degree-name{
  font-weight: 600;
  font-size: 18px;
  color: #FFFFFF;
}

.school-name{
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
  color: #A098B6;
}

.footer-border{
  border-top: solid 1px #685F81 !important;
}

.copyright{
  font-size: 14px;
  font-weight: lighter;
  line-height: 20px;
  color: #A098B6;
}

input::-webkit-input-placeholder, textarea::-webkit-input-placeholder{
  font-weight: 100;
}

input::-moz-placeholder, textarea::-moz-placeholder{
  font-weight: 100;
}

input:-ms-input-placeholder, textarea:-ms-input-placeholder{
  font-weight: 100;
}

input::-ms-input-placeholder, textarea::-ms-input-placeholder{
  font-weight: 100;
}

input::placeholder,
textarea::placeholder{
  font-weight: 100;
}

.btn-outline-light:hover > a{
  color: #000 !important;
}





@media(max-width: 640px){
  .my-name{
    text-align: center;
  }

  .menu{
    height: 19px;
    text-align: center;
    font-size: 10px;
  }

  .menu-item{
    padding-right: 12px;
  }

  .social-icon{
    font-size: 10px;
  }

  .copyright{
    font-size: 12px;
  }

  .card-custom{
    font-size: 12px;
  }

  .degree-name{
    font-size: 14px;
  }
}

